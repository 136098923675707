import { render, staticRenderFns } from "./ProjectList.vue?vue&type=template&id=c0df6aa8&scoped=true&"
import script from "./ProjectList.vue?vue&type=script&lang=js&"
export * from "./ProjectList.vue?vue&type=script&lang=js&"
import style0 from "./ProjectList.vue?vue&type=style&index=0&id=c0df6aa8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0df6aa8",
  null
  
)

export default component.exports